import { auth } from "@/firebase";
import { CircularProgress, Container, Grid2 } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoutesMiddleware = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuth] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
      setIsLoading(false); // Se asegura de que solo se actualice después de validar
    });

    return () => unsubscribe(); // Limpiar la suscripción al desmontar
  }, []);

  if (isLoading) return <Container><Grid2 container justifyContent={'center'} alignItems={'center'}><CircularProgress /></Grid2></Container>;

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" replace />;
  }

  return <Outlet />; // ✅ Esto devuelve JSX válido
};
