import { InicioAdmin } from "@/pages/Admin/Inicio";
import BlogPost from "@/pages/BlogPost";

interface IPrivateRoute {
  path: string;
  component: () => JSX.Element;
}
type IPrivateRoutes = IPrivateRoute[];

const privatesRoutes: IPrivateRoutes = [
  {
    path: "/admin",
    component: InicioAdmin,
  },
  {
    path: "/admin/create/blog",
    component: BlogPost,
  },
  {
    path: "/admin/edit/blog/:url",
    component: BlogPost,
  },
];

export default privatesRoutes;
