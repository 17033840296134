import { deleteBlog, getAllBlogs, updateBlogVisibility } from '@/api/blogs.routes'
import { Button, Card, CardActions, CardContent, CardMedia, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid2, Skeleton, Switch, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const InicioAdmin = () => {
  const { data: blogs, isLoading: isLoadingBlogs, refetch, isRefetching } = useQuery({ queryKey: ['blogs'], queryFn: getAllBlogs })
  const navigate = useNavigate()
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
  const [blogToDelete, setBlogToDelete] = useState<{ blogId: string, imageUrl?: string }>()

  const handleVisibility = (id: string, visible: boolean) => {
    updateBlogVisibility(id, visible)
    refetch()
  }

  const handleDeleteBlog = (blogId: string, imageUrl?: string) => {
    deleteBlog(blogId, imageUrl)
    refetch()
    setOpenDeleteAlert(false)
  }

  const handleDeleteAlert = (blogId: string, imageUrl?: string) => {
    setOpenDeleteAlert(true)
    setBlogToDelete({ blogId, imageUrl })
  }
  return (
    <Container>
      <Typography variant='h1' fontSize={'4rem'} fontWeight={700}>Pagina de inico de admin</Typography>
      <Grid2 marginY={'2rem'}>
        <Typography variant='h3'>Blogs</Typography>
        {(isLoadingBlogs || isRefetching) ? <Grid2 container gap={2}> <Skeleton width={'2rem'} height={'4rem'} /> <Skeleton width={'2rem'} height={'4rem'} /> <Skeleton width={'2rem'} height={'4rem'} /> <Skeleton width={'2rem'} height={'4rem'} /> </Grid2> :
          <Grid2 container gap={2} marginY={'1rem'}>
            {blogs && blogs.map((blog) => (
              <Grid2 width={400}>
                <Card>
                  <CardMedia
                    sx={{ height: 140 }}
                    image={blog.coverImage}
                    title="green iguana"
                  />
                  <CardContent>
                    <Typography fontWeight={700}>
                      {blog?.title}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => navigate(`/admin/edit/blog/${blog.url}`)}>Editar</Button>
                    <FormControlLabel control={<Switch checked={blog.visible}
                      onChange={() => handleVisibility(blog.id, !blog.visible)} />} label="Visible" />
                    <Button size="small" color={'error'} onClick={() => handleDeleteAlert(blog.id, blog.coverImage)}>Eliminar</Button>

                  </CardActions>
                </Card>
              </Grid2>
            ))}
          </Grid2>
        }
        <Button variant='contained' onClick={() => navigate('/admin/create/blog')}>Crear Blog</Button>

        <Dialog
          open={openDeleteAlert}
          onClose={() => setOpenDeleteAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Segura que queres eliminar el blog?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Hacer esto eliminará por completo el contenido y la imagen asociada al blog.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteAlert(false)}>Cancelar</Button>
            <Button onClick={() => handleDeleteBlog(blogToDelete!.blogId, blogToDelete?.imageUrl)} autoFocus color='error'>
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid2>
    </Container>
  )
}
