import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importar los estilos de Quill

const TextEditor = ({ value, onChange }: { value: any, onChange: any }) => {
  const modules = {
    toolbar: [
      [{ 'font': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ["bold", "italic", "underline"],
      [{ 'align': [] }],
      [{ 'color': [] }],
      [{ list: "ordered" }, { list: "bullet" }, { 'list': 'check' }],
      ["link", 'image', 'video'],
      ["clean"],
    ],
  };

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      modules={modules}
      placeholder="Escribe un blog interesante..."

    />
  );
};

export default TextEditor;
