import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "@/firebase"; // Asegúrate de importar correctamente tu instancia de Firestore
import { IBlog } from "@/types/blogs.interface";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";

interface ICreateBlog {
  title: string;
  content: string;
  imageURL: string;
  blogURL: string;
  isVisible: boolean;
  seoDescription: string;
}

export const UploadBlogImage = async (image: File, blogUrl: string) => {
  try {
    const imageRef = ref(storage, `blog_covers/${blogUrl}-${Date.now()}`);
    await uploadBytes(imageRef, image);
    const imageUrl = await getDownloadURL(imageRef);

    return imageUrl;
  } catch (error) {
    return null;
  }
};
export const createBlog = async (data: ICreateBlog) => {
  try {
    await addDoc(collection(db, "blogs"), {
      title: data.title,
      content: data.content,
      url: data.blogURL,
      visible: data.isVisible,
      seoDescription: data.seoDescription,
      coverImage: data.imageURL, // URL de la imagen
      createdAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error al guardar el blog:", error);
    alert("Hubo un error al guardar el blog.");
  }
};

export const updateBlog = async (
  blogId: string,
  data: Partial<ICreateBlog>
) => {
  try {
    const blogRef = doc(db, "blogs", blogId);

    await updateDoc(blogRef, {
      title: data.title,
      content: data.content,
      url: data.blogURL,
      visible: data.isVisible,
      seoDescription: data.seoDescription,
      coverImage: data.imageURL, // URL de la imagen
    });
  } catch (error) {
    console.error("Error al actualizar la visibilidad del blog:", error);
  }
};

export const getAllBlogs = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "blogs"));
    const blogs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return blogs as unknown as IBlog[];
  } catch (error) {
    console.error("Error obteniendo blogs:", error);
    return [];
  }
};

export const updateBlogVisibility = async (
  blogId: string,
  isVisible: boolean
) => {
  try {
    const blogRef = doc(db, "blogs", blogId);

    await updateDoc(blogRef, {
      visible: isVisible,
    });
  } catch (error) {
    console.error("Error al actualizar la visibilidad del blog:", error);
  }
};

export const deleteBlog = async (blogId: string, imageUrl?: string) => {
  try {
    const blogRef = doc(db, "blogs", blogId);

    // Eliminar el documento del blog en Firestore
    await deleteDoc(blogRef);
    console.log(`Blog ${blogId} eliminado correctamente de Firestore.`);

    // Si hay una imagen asociada, eliminarla de Firebase Storage
    if (imageUrl) {
      const storage = getStorage();
      const decodedUrl = decodeURIComponent(imageUrl); // Decodifica la URL para obtener el path correcto
      const pathMatch = decodedUrl.match(/o\/(.*?)\?/); // Extrae el path del archivo en Storage

      if (pathMatch && pathMatch[1]) {
        const imagePath = pathMatch[1]; // Ruta en Storage
        const imageRef = ref(storage, imagePath);

        await deleteObject(imageRef);
        console.log(`Imagen eliminada: ${imagePath}`);
      } else {
        console.warn("No se pudo extraer la ruta de la imagen correctamente.");
      }
    }
  } catch (error) {
    console.error("Error al eliminar el blog o la imagen:", error);
  }
};

export const deleteImage = async (imageURL: string) => {
  // Si hay una imagen asociada, eliminarla de Firebase Storage
  try {
    const storage = getStorage();
    const decodedUrl = decodeURIComponent(imageURL); // Decodifica la URL para obtener el path correcto
    const pathMatch = decodedUrl.match(/o\/(.*?)\?/); // Extrae el path del archivo en Storage

    if (pathMatch && pathMatch[1]) {
      const imagePath = pathMatch[1]; // Ruta en Storage
      const imageRef = ref(storage, imagePath);

      await deleteObject(imageRef);
      console.log(`Imagen eliminada: ${imagePath}`);
    } else {
      console.warn("No se pudo extraer la ruta de la imagen correctamente.");
    }
  } catch (error) {
    throw Error("Error al eliminar la imagen: " + error);
  }
};

export const getBlogByUrl = async (blogUrl: string) => {
  try {
    const blogsRef = collection(db, "blogs");
    const q = query(blogsRef, where("url", "==", blogUrl));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const blogData = querySnapshot.docs[0].data();
      return { id: querySnapshot.docs[0].id, ...blogData } as unknown as IBlog;
    } else {
      console.warn("No se encontró un blog con la URL:", blogUrl);
      return null;
    }
  } catch (error) {
    console.error("Error al obtener el blog:", error);
    return null;
  }
};

export const getVisibleBlogs = async () => {
  try {
    const blogsRef = collection(db, "blogs");
    const q = query(blogsRef, where("visible", "==", true));
    const querySnapshot = await getDocs(q);

    const blogs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return blogs as unknown as IBlog[];
  } catch (error) {
    console.error("Error obteniendo blogs visibles:", error);
    return [];
  }
};
