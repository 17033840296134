import { createBlog, deleteImage, getBlogByUrl, updateBlog, UploadBlogImage } from "@/api/blogs.routes";
import TextEditor from "@/components/TextEditor/TextEditor";
import { Button, CircularProgress, Container, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup, FormLabel, Grid2, Switch, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const BlogPost = () => {
  const [content, setContent] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [title, setTitle] = useState("");
  const [isVisible, setVisibleChecked] = useState(true)
  const [image, setImage] = useState<File | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [blogURL, setBlogURL] = useState<string | null>(null)
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)

  const params = useParams<{ url: string }>();
  const navigate = useNavigate()

  const { data, isLoading } = useQuery({ queryKey: ['blog', params.url], queryFn: () => getBlogByUrl(params.url!), enabled: !!params.url })
  console.log('imageURL', imageURL)
  console.log('data', data)
  useEffect(() => {
    if (params.url && data) {
      setTitle(data.title)
      setContent(data.content)
      setVisibleChecked(data.visible)
      setImageURL(data.coverImage)
      setSeoDescription(data.seoDescription)
    }
  }, [data])

  const handleChange = () => {
    setVisibleChecked(!isVisible)
  }
  const generateUrl = (title: string) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-") // Reemplaza espacios con guiones
      .replace(/[^a-z0-9-]/g, ""); // Elimina caracteres especiales
  };


  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (image) {
      console.log('se ejecuto')
      UploadBlogImage(image, blogURL!).then((url) => {
        setImageURL(url)
      })
    }
  }, [image])

  useEffect(() => {
    const blogUrl = generateUrl(title);
    setBlogURL(blogUrl)
  }, [title])

  const handleSave = async () => {
    if (!title.trim() || !content.trim()) {
      alert("El título y el contenido no pueden estar vacíos.");
      return;
    }
    if (!imageURL) {
      alert("Seleccioná una imagen.");
      return;
    }
    try {
      if (blogURL && imageURL) {
        if (params.url && data) {
          await updateBlog(data.id, { blogURL, content, imageURL, title, isVisible, seoDescription })
        } else {
          await createBlog({ blogURL, content, imageURL, title, isVisible, seoDescription })
        }
      } else {
        throw Error("Falta Blog URL e image URL")
      }

      alert("Blog guardado correctamente");
      navigate('/admin')
    } catch (error) {
      console.error("Error al guardar el blog:", error);
      alert("Hubo un error al guardar el blog.");
    }
  };

  const handleDeleteImage = async (url: string) => {
    deleteImage(url)
    setImageURL(null)
    if (params.url && data) {
      await updateBlog(data.id, { imageURL: undefined })
    }
    setOpenDeleteAlert(false)
  }

  if (isLoading) return <Container><Grid2 container justifyContent={'center'} alignItems={'center'}><CircularProgress /></Grid2> </Container>
  return (
    <Container>
      <h2>Crear un blog</h2>
      <FormGroup sx={{ marginTop: '1rem' }}>
        <FormLabel>Titulo</FormLabel>
        <TextField
          type="text"
          placeholder="Título del blog"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
        />
      </FormGroup>
      {Boolean(imageURL) &&
        <>
          <img src={imageURL!} alt={'asdasd'} />
          <Button color='error' onClick={() => setOpenDeleteAlert(true)}>Eliminar</Button>
        </>
      }
      {(blogURL && !Boolean(imageURL)) &&
        <input type="file" accept="image/*" onChange={handleImageChange} />
      }

      <TextEditor value={content} onChange={setContent} />
      <FormGroup sx={{ marginTop: '1rem' }}>
        <FormLabel>Descripción SEO</FormLabel>
        <TextField
          type="text"
          placeholder="Introduce el contenido en Chat GPT y pedile que te cree una descripción para el metatag og:description"
          value={seoDescription}
          onChange={(e) => setSeoDescription(e.target.value)}
          style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
        />
      </FormGroup>


      <FormGroup>
        <FormControlLabel control={<Switch checked={isVisible}
          onChange={handleChange} />} label="Visible" />

      </FormGroup>
      <Grid2 container justifyContent={'right'} marginY={'1rem'}>
        <Button variant="contained" onClick={handleSave}>Guardar</Button>
      </Grid2>

      <Dialog
        open={openDeleteAlert}
        onClose={() => setOpenDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Segura que queres eliminar la imagen?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDeleteAlert(false)}>Cancelar</Button>
          <Button onClick={() => handleDeleteImage(imageURL!)} autoFocus color='error'>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BlogPost;
