import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import privatesRoutes from "./privates.routes";
import { PrivateRoutesMiddleware } from "./PrivateRoutes";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Routes>
        {routes.map((routeItem) => {
          return (
            <Route
              path={routeItem.path}
              Component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
        <Route element={<PrivateRoutesMiddleware />}>.
          {privatesRoutes.map((privateRoute) => {
            return (
              <Route
                path={privateRoute.path}
                Component={privateRoute.component}
              />
            )
          })}
        </Route>
        <Route
          path={"/admin/login"}
          Component={lazy(() => import(`../pages/Login`))}
        />
        <Route
          path={"*"}
          Component={lazy(() => import(`../pages/404`))}
        />
      </Routes>
      <Footer />
    </Suspense >
  );
};

export default Router;
